/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import changeShift from "./changeShift.vue";
import changeShiftDay from "./changeShiftDay.vue";
import changeOtherDay from './changeOtherDay.vue';
import { Dialog } from "vant";
import Toast from "vux/src/components/toast/index.vue";
export default {
  name: 'ChangeShift',
  components: {
    changeShift: changeShift,
    changeShiftDay: changeShiftDay,
    changeOtherDay: changeOtherDay
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    this.$utils.Store.removeItem('oldClassesName');
    this.$utils.Store.removeItem('oldClasses');
    this.$utils.Store.removeItem('changeDate');
    this.$utils.Store.removeItem('reason');
    next();
  },
  data: function data() {
    return {
      actions: this.$api.Base.dev + '/management/insertPhoto',
      //上传图片接口
      bh: '00202',
      //假类编号
      classesList: [{
        name: '白班早晚',
        value: '1'
      }],
      newClasses: this.$utils.Store.getItem('newClasses') ? JSON.parse(this.$utils.Store.getItem('newClasses')) : [],
      oldClassesName: this.$utils.Store.getItem('oldClassesName') ? this.$utils.Store.getItem('oldClassesName') : '',
      //原班次名称
      oldClasses: this.$utils.Store.getItem('oldClasses') ? this.$utils.Store.getItem('oldClasses') : '',
      //原班次编号
      startDate: '',
      //今天日期
      changeDate: this.$utils.Store.getItem('changeDate') ? this.$utils.Store.getItem('changeDate') : '',
      //调班时间
      endTime: '',
      //开始时间
      reason: this.$utils.Store.getItem('reason') ? this.$utils.Store.getItem('reason') : '',
      //外出目的
      inputParam: [{
        name: 'att_id',
        value: 0
      }, {
        name: 'base64String',
        value: ''
      }, {
        name: 'typeId',
        value: -1
      }],
      imgList: [],
      //图片列表数组
      limitNum: 5,
      //最多上传图片张数
      depSerial: this.$utils.Store.getItem('orgId'),
      //企业ID
      depName: this.$utils.Store.getItem('orgName'),
      //企业名称
      imgPath: [],
      //图片地址
      imgIndex: 0,
      //图片数组下标
      icon: 'icon-jiahao',
      //icon加号
      //  ----以下是lwt的变量--2020-07-01
      approve: [],
      //要提交的审批节点列表
      allUserList: [],
      //获取过来的审批节点
      process: 0,
      //0简单审批 1条件审批
      userSerial: this.$utils.Store.getItem('userSerial'),
      //人员编号
      approveConditionId: '',
      approveSettingId: '',
      cc: {},
      //抄送人
      haveCC: false,
      //有没有抄送人
      showLevel: '',
      sbmitDis: true,
      //是否可以点击
      currentIndex: 0,
      listMenu: [{
        title: '班次调整',
        id: 1
      }, {
        title: '按天换班',
        id: 2
      }, {
        title: '与他人调班',
        id: 3
      }]
    };
  },
  computed: {
    isDisabled: function isDisabled() {
      if (this.sbmitDis && this.changeDate != '' && this.oldClasses != '' && this.newClasses.length > 0 && this.reason != '') {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    //
    showCurrentItem: function showCurrentItem(item, index) {
      this.currentIndex = index;
    },

    /** function 获取服务器时间---今日日期
     *   wzx
     *   2019-01-11
     **/
    getSysDate: function getSysDate() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.startDate = data.sysDate.substring(0, 10);
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },

    /** function 获取外出事由
     *   wzx
     *   2019-01-05
     **/
    getReason: function getReason(val) {
      this.reason = val;
      this.$utils.Store.setItem('reason', val);
    },

    /** function 遍历数组是否少
     *   wzx
     *  202-0706
     **/
    ergArray: function ergArray(list) {
      if (!list) {
        return true;
      }

      var uindex = list.findIndex(function (item) {
        return !item.userSerial[0];
      });
      return uindex != -1;
    },

    /** function 获取调班日期
     *   wzx
     *   2019-01-05
     **/
    changeDay: function changeDay(val) {
      this.changeDate = val;
      this.$utils.Store.setItem('changeDate', val);
      this.getBanci();
    },

    /** function 获取新班次
     *   wzx
     *   2019-01-05
     **/
    changeShift: function changeShift(val) {
      this.newClasses = val;
      this.$utils.Store.setItem('newClasses', JSON.stringify(val)); // this.getNodeList()
    },

    /** function 获取原班次和班次列表
     *   wzx
     *   2019-01-08
     **/
    getBanci: function getBanci() {
      var _this2 = this;

      this.$api.ChangeShift.getBanci({
        tbrq: this.changeDate,
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.oldClassesName = data.banci.mc;
          _this2.oldClasses = data.banci.bh;

          _this2.$utils.Store.setItem('oldClassesName', data.banci.mc);

          _this2.$utils.Store.setItem('oldClasses', data.banci.bh);

          var allBC = data.BanciAll;
          allBC.forEach(function (item, i) {
            if (item.mc == '白班早晚') {
              //  this.reasonList[0].value=item.bh;
              //  this.flagLeave=[item.bh]
              _this2.classesList[0].value = item.bh;
              _this2.classesList[0].process = item.process;
              _this2.bh = item.bh;
            } else {
              //  this.reasonList.push({name: item.mc, value: item.bh ,index:i})
              _this2.classesList.push({
                name: item.mc,
                value: item.bh,
                index: i,
                process: item.process
              });
            }
          });
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    onChange: function onChange() {},
    onShow: function onShow() {},
    onHide: function onHide() {},

    /** function 当前提交审批节点中是否有对应的节点
     *   lwt
     *   2020-07-03
     * */
    inspectObj: function inspectObj(sub) {
      var index = 0;
      var flag = this.approve.some(function (items, i) {
        if (items.level == sub.level) {
          index = i;
          return true;
        }
      });
      return {
        index: index,
        flag: flag
      };
    },

    /** function 获取子集的节点对象
     *   lwt
     *   2020-07-03
     * */
    getSubUserObj: function getSubUserObj(appr) {
      if (appr.cc && appr.cc == 1) {
        this.cc = appr;
        return;
      }

      if (this.approve.length == 0) {
        this.approve.push(appr);
      } else {
        var insUser = this.inspectObj(appr);

        if (insUser.flag) {
          //如果有重复的
          this.approve[insUser.index] = appr;
        } else {
          //没有重复的
          this.approve.push(appr);
        }
      }
    },

    /** function 获取可审批的节点
     *   lwt
     *   2020-07-04
     * */
    getNodeList: function getNodeList() {
      var _this3 = this;

      this.$utils.Tools.loadShow();
      this.haveCC = false;
      this.allUserList = [];
      this.cc = {};
      var params = {
        approveCode: '4',
        //请假
        subCode: "00202",
        startTime: this.kssj,
        //开始时间
        endTime: this.jssj,
        //结束时间
        userSerial: this.userSerial //人员序列

      };
      this.$api.Appr.getApplicants(params).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (code == '602') {
          _this3.$utils.Tools.loadHide();

          Dialog({
            title: '提示',
            message: msg,
            messageAlign: 'left',
            width: '70%',
            closeOnPopstate: true
          });
          return;
        }

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.loadHide();

          _this3.$utils.Tools.toastShow(msg);
        } else {
          _this3.$utils.Tools.loadHide();

          _this3.showLevel = data.showLevel;

          if (data.showLevel == '1') {
            _this3.allUserList = data.approve;
          } else {
            for (var i = 0; i < data.approve.length; i++) {
              if (data.approve[i].level < data.showLevel) {
                (function () {
                  var userItem = {};
                  var user = [];
                  data.approve[i].user.some(function (sub) {
                    user.push(sub.userSerial);
                  });
                  userItem.level = data.approve[i].level;
                  userItem.way = data.approve[i].levelWay;
                  userItem.levelId = data.approve[i].levelId;
                  userItem.userSerial = user;

                  _this3.approve.push(userItem);
                })();
              } else {
                _this3.allUserList.push(data.approve[i]);
              }
            }
          }

          if (data.ccSet) {
            _this3.haveCC = true;
            _this3.allUserList[_this3.allUserList.length] = data.ccSet; //抄送
          }

          _this3.approveConditionId = data.approveConditionId;
          _this3.approveSettingId = data.approveSettingId;
          _this3.process = data.process;
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },

    /** function 提交出差申请
     *   wzx
     *   2019-01-05
     * */
    submitClick: function submitClick() {
      var _this4 = this;

      if (this.ergArray(this.approve)) {
        Toast('未选择审批人');
        return;
      } else if (this.haveCC && !this.cc.userSerial[0]) {
        Toast('未选择抄送人');
        return;
      }

      this.sbmitDis = false;
      this.imgPath = [];

      var _iterator = _createForOfIteratorHelper(this.imgList),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var imgs = _step.value;
          this.imgPath.push(imgs.serverId);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      var imgPath = JSON.stringify(this.imgPath).replace(/\[|]|"/g, '');
      this.$utils.Tools.loadShow();
      var businessEntity = {
        bh: "00202",
        bz: this.reason,
        imgPath: imgPath,
        processId: '',
        qjlx: 1,
        tbrq: this.changeDate,
        xbc: this.newClasses[0],
        ybc: this.oldClasses,
        userSerial: this.$utils.Store.getItem('userSerial'),
        xh: ''
      };
      var params = {
        businessEntity: businessEntity,
        approveCode: 4,
        approveSettingId: this.approveSettingId,
        approveConditionId: this.approveConditionId,
        approveCodeSub: "00202",
        petitioner: this.$utils.Store.getItem('userSerial'),
        cc: [this.cc],
        showLevel: this.showLevel,
        approve: this.approve
      };
      this.$api.Appr.apply(params).then(function (response) {
        _this4.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg;
        _this4.sbmitDis = true;

        if (code == '625' || code == '626') {
          _this4.$utils.Tools.loadHide();

          Dialog.alert({
            title: '提示',
            message: msg,
            messageAlign: 'left',
            width: '70%',
            closeOnPopstate: true
          }).then(function () {
            // on close
            _this4.$router.go(0);
          });
          return;
        }

        if (+code !== _this4.$code.success) {
          Toast(msg);
        } else {
          Toast('提交成功');

          _this4.$router.go(-1); // this.$router.replace('/MyApply')

        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  destroyed: function destroyed() {}
};